import React, { useState, useEffect, useRef } from 'react';
import Message from './message';
import DOMPurify from 'dompurify';
import './chat.css';

const Chat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const socket = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const heartbeatIntervalRef = useRef(null);
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    // Helper function to check if the token is expired
    const isTokenExpired = (token) => {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        return decodedToken.exp * 1000 < Date.now();  // Expiration in milliseconds
    };

    // Fetch new token from the server
    const fetchNewToken = async () => {
        try {
            const response = await fetch('https://www.lassedesk.top/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`Token request failed with status: ${response.status}`);
            }
    
            const data = await response.json();
            localStorage.setItem('jwtToken', data.access_token); // Store token in localStorage
            return data.access_token;
        } catch (error) {
            console.error('Failed to fetch new token:', error);
            return null;
        }
    };
    
    // Automatically get token or refresh if expired
    const getToken = async () => {
        const storedToken = localStorage.getItem('jwtToken');
        if (storedToken && !isTokenExpired(storedToken)) {
            return storedToken; // Use existing token if valid
        } else {
            return await fetchNewToken(); // Fetch a new token if expired or missing
        }
    };

    // Connect to WebSocket with valid token
    const connectWebSocket = async () => {
        const token = await getToken();

        if (!token) {
            console.error("No valid JWT token found, cannot connect to WebSocket.");
            return;
        }

        const wsUrl = `wss://www.lassedesk.top/ws/asob-chat?token=${token}`;
        socket.current = new WebSocket(wsUrl);

        socket.current.onmessage = event => {
            let newMessage;
            try {
                newMessage = JSON.parse(event.data);
            } catch (error) {
                newMessage = { text: event.data, sender: 'server' };
            }
            newMessage.text = DOMPurify.sanitize(newMessage.text, { USE_PROFILES: { html: true } });
            setLoading(false);
            setMessages(prev => {
                const updatedMessages = [...prev, newMessage];
                return updatedMessages;
            });
        };

        socket.current.onopen = () => {
            console.log("WebSocket connection established!");
            clearTimeout(socket.current.reconnectTimeout);
            socket.current.heartbeatInterval = setInterval(() => {
                if (socket.current.readyState === WebSocket.OPEN) {
                    socket.current.send(JSON.stringify({ type: 'heartbeat' }));
                }
            }, 30000);  // Send heartbeat every 30 seconds
        };

        socket.current.onclose = () => {
            console.log("WebSocket connection closed! Reconnecting...");
            clearInterval(socket.current.heartbeatInterval);
            socket.current.reconnectTimeout = setTimeout(connectWebSocket, 1000);  // Reconnect after 1 second
        };

        socket.current.onerror = error => {
            console.error("WebSocket error:", error);
            socket.current.close();
        };
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socket.current) socket.current.close();
            if (reconnectTimeoutRef.current) clearTimeout(reconnectTimeoutRef.current);
            if (heartbeatIntervalRef.current) clearInterval(heartbeatIntervalRef.current);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && shouldScrollToBottom()) {
            scrollToLastMessage();
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && shouldScrollToBottom()) {
            scrollToLastMessage();
        }
    }, [messages, isOpen]);

    useEffect(() => {
        const resizeIcon = document.getElementById("resize-icon");
        const chatContainer = chatContainerRef.current;

        let startX, startY, startWidth, startHeight;

        const onMouseMove = (e) => {
            const newWidth = Math.max(300, startWidth + (startX - e.clientX)); // Increase width when moving left
            const newHeight = Math.max(400, startHeight + (startY - e.clientY)); // Increase height when moving up

            chatContainer.style.width = `${newWidth}px`;
            chatContainer.style.height = `${newHeight}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };

        const onMouseDown = (e) => {
            startX = e.clientX;
            startY = e.clientY;
            startWidth = chatContainer.offsetWidth;
            startHeight = chatContainer.offsetHeight;

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        };

        if (resizeIcon) {
            resizeIcon.addEventListener("mousedown", onMouseDown);
        }

        return () => {
            if (resizeIcon) {
                resizeIcon.removeEventListener("mousedown", onMouseDown);
            }
        };
    }, [isOpen]);


    const sendMessage = () => {
        if (input.trim()) {
            const sanitizedInput = DOMPurify.sanitize(input);
            setMessages(prev => {
                const updatedMessages = [...prev, { text: sanitizedInput, sender: 'user' }];
                if (isOpen && shouldScrollToBottom()) {
                    scrollToLastMessage();
                }
                return updatedMessages;
            });
            socket.current.send(JSON.stringify({ text: sanitizedInput, sender: 'user' }));
            setInput('');
            setLoading(true);
        }
    };

    const handleInput = event => setInput(event.target.value);
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            sendMessage();
            event.preventDefault();
        }
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setTimeout(() => {
                if (isOpen) {
                    inputRef.current?.focus();
                }
            }, 300);
        }
    };

    const shouldScrollToBottom = () => {
        const chatMessagesElement = document.getElementById('chat-messages');
        return chatMessagesElement.scrollTop + chatMessagesElement.clientHeight < chatMessagesElement.scrollHeight;
    };

    const scrollToLastMessage = () => {
        const chatMessagesElement = document.getElementById('chat-messages');
        const lastMessageElement = messagesEndRef.current?.previousSibling;

        if (chatMessagesElement && lastMessageElement) {
            const lastMessageTop = lastMessageElement.offsetTop;
            const lastMessageHeight = lastMessageElement.scrollHeight;
            const chatHeight = chatMessagesElement.clientHeight;

            if (lastMessageHeight > chatHeight) {
                chatMessagesElement.scroll({
                    top: lastMessageTop,
                    behavior: 'smooth',
                });
            } else {
                chatMessagesElement.scroll({
                    top: chatMessagesElement.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <div className="chat-widget" id="chat-widget" onClick={(e) => e.stopPropagation()}>
            <div className="chat-icon" id="chat-icon" onClick={toggleChat}>
                &#x1F4AC;
            </div>
            <div ref={chatContainerRef} id="chat-container" className={`chat-container ${isOpen ? 'expanded' : ''}`}>
                <div className="chat-header" id="chat-header">
                    <img src="https://lassedesk.top/asob-0924/Logo-ASOB.svg" alt="Logo" className="chat-logo" />
                    <span>ASOB KI-Assistent</span>
                    
                    <button className="close-chat" id="close-chat" onClick={toggleChat}>&#10005;</button>
                </div>
                <div id="chat-messages" className="messages">
                    {messages.map((msg, index) => (
                        <Message key={index} text={msg.text} sender={msg.sender} />
                    ))}
                    {loading && (
                        <div className="loading-indicator" id="loading-indicator">
                            <div className="dot-flashing"></div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="input-area" id="input-area">
                    <input
                        ref={inputRef}
                        className="form-control"
                        id="form-control"
                        value={input}
                        onChange={handleInput}
                        onKeyDown={handleKeyDown}
                        placeholder="Stellen Sie eine Frage..."
                    />
                    <button className="send-button" id="send-button" onClick={sendMessage}>&#9658;</button>
                </div>
            </div>
        </div>
    );
    
};

export default Chat;

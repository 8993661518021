// src/Message.js
import React, { useEffect } from 'react'; // Import useEffect here
import './chat.css'; // Ensure the CSS is imported here

const Message = ({ text, sender }) => {
    useEffect(() => {
        // Select all links within this message component and add target="_blank"
        const links = document.querySelectorAll('.message a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer'); // Security best practice for external links
        });
    }, [text]); // Run this effect every time 'text' changes
    
    return (
        <div className={`message ${sender}`}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default Message;
// src/App.js
import React from 'react';
import Chat from './chat';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './chat.css';

function App() {
    return (
        <div className="chat-widget">
            <Chat />
        </div>
    );
}

export default App;
